import React, { useEffect } from "react";
import "./Subscribe.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Container,
  Row,
  Col,
  Image,
  Modal,
  ModalHeader,
  Button,
} from "react-bootstrap";
import ZoomImage from "./common/ImageHover";
import { useState } from "react";
import { useUser } from "../UserContext";
import instance from "./common/Api";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Loader from "./common/Loader";

const Subscribe = (props) => {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredGold, setIsHoveredGold] = useState(false);
  const [isHoveredSilver, setIsHoveredSilver] = useState(false);
  const [isHoveredBasic, setIsHoveredBasic] = useState(false);

  const [basicPlans, setBasicPlans] = useState(null);
  const [silverPlans, setSilverPlans] = useState(null);
  const [goldPlans, setGoldPlans] = useState(null);
  const [sixDaysPlans, setSixDaysPlans] = useState(null);

  const [activePlans, setActivePlans] = useState(null);

  const navigate = useNavigate();

  const [showAgePopUp, setShowAgePopUp] = useState(false);

  const [countryNames, setCountryNames] = useState([]);

  const [newCountry, setNewCountry] = useState("");
  // const { userData } = useUser();

  const [selectedAge, setSelectedAge] = useState("18 years");
  const [locationData, setLocationData] = useState(null);
  const [userCity, setUserCity] = useState(null);
  const [ApplocationData, setAppLocationData] = useState(null);

  const [popUpPlan, setPopUpPlan] = useState(null);
  const [popUpOldPrice, setPopUpOldPrice] = useState(null);
  const [popUpPrice, setPopUpPrice] = useState(null);
  const [popUpSub_id, setPopUpSub_id] = useState(null);
  const [isChecked, setIsChecked] = useState(true);
  const [showPayOptions, setShowPayOptions] = useState(false);
  const [ageIdx, setAgeIdx] = useState(0);
  // Function to filter plans based on title and set states
  const navigates = useNavigate();

  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [isMobile, setIsMobile] = useState(isMobileDevice());

  const filterPlans = (plans) => {
    const gold = plans.content.filter(
      (plan) => plan.title.toLowerCase() === "gold"
    );
    const basic = plans.filter((plan) => plan.title.toLowerCase() === "basic");
    // Filter other plan types if needed

    setGoldPlans(gold);
    setBasicPlans(basic);
    // Set states for other plan types if needed
  };
  const formatDate = function (dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}-${month}-${year}`;
  };

  const pushToLogin = () => {
    navigate("/login");
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked); // Update the state based on checkbox's checked value
  };

  const MakePhonePePay = async () => {
    //console.log("MakePhonePePay", payload.amount);
    //subscription_id
    // ip
    if (selectedAge === "" || selectedAge === "Select your age") {
      alert("Please select your age");
    } else {
      const payload = {
        cityName: userCity,
        age: selectedAge,
        amount: popUpPrice,
        ip: props.ip,
        id: props.userData.id,
        subscription_id: popUpSub_id,
      };

      // console.error("Error fetching payload:", payload);
      instance
        .post(
          `/user/phone_pe?amount=${payload.amount}&subscription_id=${payload.subscription_id}&id=${payload.id}&ip=${payload.ip}&cityName=${payload.cityName}&age=${payload.age}`
        )
        .then((response) => {
          window.location.href =
            response.data.data.instrumentResponse.redirectInfo.url;
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          // window.location.href = "/";
          setLoading(false); // Set loading to false when fetch is complete
        });
    }
  };

  const makePayment = (data) => {
    // //console.log("makePayment", data);
    // //console.log("data.listedPrice", data.listedPrice);
    // //console.log("data.subscription_id", data.subscription_id);
    // //console.log("props.ip", props.ip);
    // //console.log("userData", props.userData.id);
    setPopUpPlan(data.title);
    setPopUpOldPrice(data.amount);
    setPopUpPrice(data.listedPrice);
    setPopUpSub_id(data.subscription_id);

    // setPayloadData(data);
    // if (selectedAge !== "Select you age") {
    //   const payload = {
    //     city: userCity,
    //     age: selectedAge,
    //     amount: data.listedPrice,
    //     ip: props.ip,
    //     id: props.userData.id,
    //     subscription_id: data.subscription_id,
    //   };
    //   // setFinalPayload(payload);

    //   // MakePhonePePay(payload);
    // } else {
    // }
    setShowAgePopUp(true);
  };

  const ageArray = Array.from(
    { length: 99 - 18 + 1 },
    (_, index) => index + 18
  );

  // useEffect(() => {
  //   const fetchLocation = async () => {
  //     try {
  //       const response = await fetch("https://ipinfo.io/json");
  //       if (!response.ok) {
  //         throw new Error("Failed to fetch location data");
  //       }
  //       const data = await response.json();
  //       setLocationData(data);
  //     } catch (error) {
  //       setError(error.message);
  //     }
  //   };

  //   fetchLocation();
  // }, []);

  useEffect(() => {
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    console.log(navigates.Array, "History");
    if (props.userData != null) {
      instance
        .post(`/getUsersState?ip=${props.ip}&id=${props.userData.id}`)
        .then((response) => {
          // setAppLocationData(response.data);
          // console.log("/getUsersState", response.data);
          // console.log("/getUsersState", response.data[0].cityName);
          // console.log("/getUsersState", response.data[0].countryName);
          if (response.data[0].countryName === "India") {
            setUserCity(response.data[0].cityName);
          } else {
            setUserCity(null);
          }

          // console.log("/getUsersState", response.data[1].states);
          setAppLocationData(response.data[1].states);
          //console.log(active[0], "<active");
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // console.log("props...:", props);
    if (props.userData != null) {
      instance
        .post(`/subscription_plans_web?id=${props.userData.id}&ip=${props.ip}`)
        .then((response) => {
          setSubscriptionData(response.data);
          //console.log("response.data", response.data);
          const active = response.data.active_plan;
          //console.log(active[0], "<active");
          if (response.data && response.data.active_plan.length > 0) {
            setActivePlans(response.data.active_plan[0]);
          }
          //console.log(response.data.active_plan);

          const goldPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "gold"
          );
          const silverPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "silver"
          );
          const basicPlans = response.data.content.filter(
            (plan) => plan.title.toLowerCase() === "basic"
          );
          const sixDaysPlans = response.data.content.filter(
            (plan) => plan.title.trim().toLowerCase() === "six days"
          );
          //console.log("Basic Plans:", goldPlans[0]);
          setBasicPlans(basicPlans[0]);
          setSilverPlans(silverPlans[0]);
          setGoldPlans(goldPlans[0]);
          setSixDaysPlans(sixDaysPlans[0]);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
      //console.log("User ID:", props.userData.id);
    } else {
      //console.log("User ID not found");
      setLoading(false);
    }
    // filterPlans(subscriptionData);
    //console.log("subscriptionData", props.userData);
  }, []);

  const handleSelectChange = (event, idx) => {
    setAgeIdx(idx);
    // console.log(`${event.target.value + " years"}`);
    console.log(`${event + " years"}`);
    if (event !== null) {
      setSelectedAge(`${event + " years"}`); // Update selectedAge state with the value of the selected option
    }
  };

  return (
    <>
      <Modal
        show={showAgePopUp}
        onHide={() => {
          setShowAgePopUp(false);
          setShowPayOptions(false);
        }}
        style={{ marginTop: "1rem" }}
      >
        <ModalHeader
          style={{
            padding: "0",
            backgroundColor: "black",
            borderBottom: "none",
          }}
        >
          <h6
            className="text-white"
            style={{
              marginTop: "1px",
              marginLeft: "10px",
            }}
          >
            Select payment options for subscribing
          </h6>{" "}
        </ModalHeader>
        <Modal.Body
          style={{
            // backgroundImage: `url(./check-network.png)`,
            // backgroundSize: "cover",
            color: "white",
            textAlign: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            backgroundColor: "black",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              maxHeight: "300px", // Set your desired fixed height here
              // overflowY: "scroll",
              width: "100%", // Ensure full width
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "10px",
              }}
            >
              <p style={{ display: "inline", color: "#18e4cf" }}>
                {popUpPlan && popUpPlan}
              </p>
              <p
                style={{
                  display: "inline",
                  marginLeft: "auto",
                  textDecoration: "line-through",
                  color: "#18e4cf",
                }}
              >
                ₹ {popUpOldPrice && popUpOldPrice}
              </p>

              <p style={{ display: "inline" }}>₹ {popUpPrice && popUpPrice}</p>
            </div>

            {/* {countryNames &&
              countryNames.map((item, idx) => (
                <p
                  key={idx}
                  style={{ cursor: "pointer" }}
                  // onClick={() => handleItemClick(item)}
                >
                  {item}
                </p> 
            ))}*/}
          </div>
          {showPayOptions ? (
            <>
              {/* <Button
                variant="secondary"
                style={{
                  marginTop: "20px",
                  color: "white",
                  width: "100%",
                  backgroundColor: "#222529",
                }}
                // onClick={MakePhonePePay}
              >
                Debit Card | Credit Card | Net Banking
              </Button>
              <Button
                variant="secondary"
                style={{
                  marginTop: "20px",
                  color: "white",
                  width: "100%",
                  backgroundColor: "#222529",
                }}
                // onClick={MakePhonePePay}
              >
                Google Pay | UPI Payment
              </Button> */}
              <Button
                variant="secondary"
                style={{
                  marginTop: "20px",
                  color: "white",
                  width: "100%",
                  backgroundColor: "#222529",
                }}
                onClick={MakePhonePePay}
              >
                PhonePe | UPI Payment
              </Button>
            </>
          ) : (
            <>
              {userCity && (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="dark"
                    id="dropdown-basic"
                    // style={{ marginRight: "18rem" }}
                  >
                    {userCity && userCity}
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{ height: "10rem", overflowY: "auto" }}>
                    {ApplocationData &&
                      ApplocationData.map((item, index) => (
                        <Dropdown.Item
                          key={index}
                          onClick={() => setUserCity(item.name)}
                        >
                          {item.name}
                        </Dropdown.Item>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <p style={{ marginRight: "auto", fontWeight: "700" }}>
                Select your age:{" "}
              </p>
              <div
                style={{
                  maxHeight: "8rem", // Set your desired fixed height here
                  overflowY: "scroll",
                  backgroundColor: "#212529",
                  borderRadius: "10px",
                  padding: "10px",
                  width: "100%",
                }}
              >
                {ageArray &&
                  ageArray.map((item, idx) => (
                    <p
                      key={idx}
                      style={{
                        cursor: "pointer",
                        backgroundColor: ageIdx === idx ? "#58b7aa" : "",
                        borderRadius: "3px",
                      }}
                      onClick={() => handleSelectChange(item, idx)}
                    >
                      {item} Years
                    </p>
                  ))}
              </div>
              {/* <Form.Select
                size="sm"
                aria-label="Default select example"
                onChange={handleSelectChange}
                style={{
                  marginTop: "1rem",
                  //   height: "2.5rem", // Set a fixed height for the select box
                  overflowY: "auto", // Enable vertical scrolling if options exceed the fixed height
                }}
              >
                <option>Select your age</option>
                {ageArray &&
                  ageArray.map((item, index) => (
                    <option value={item} key={index}>
                      <option value={item} key={index}>
                        {item} Years
                      </option>
                    </option>
                  ))}
              </Form.Select> */}
              <div>
                <p style={{ fontSize: "12px", marginTop: "10px" }}>
                  <input
                    type="checkbox"
                    style={{
                      marginRight: "5px",
                      color: isChecked ? "#58b7aa" : "white",
                    }}
                    checked={isChecked}
                    onChange={handleCheckboxChange} // Call handleCheckboxChange function on checkbox change
                  />
                  <span style={{ color: "white" }}>
                    I have Read and Accepted the{" "}
                  </span>
                  <span style={{ color: "#58b7aa" }}>Terms and condition</span>
                  <span style={{ color: "white" }}> and </span>
                  <span style={{ color: "#58b7aa" }}>Privacy Policy</span>
                  <span style={{ color: "white" }}> and </span>
                  <span style={{ color: "#58b7aa" }}>Refund Policy</span>
                  <span style={{ color: "white" }}>.</span>
                </p>
              </div>
              <Button
                variant="secondary"
                disabled={!isChecked || selectedAge === null}
                style={{
                  marginTop: "20px",
                  color: "white",
                  backgroundColor: "#58b7aa",
                }}
                onClick={() => setShowPayOptions(true)}
              >
                Continue
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
      {props && props.userData != null ? (
        <>
          {activePlans != null ? (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Spinner animation="border" />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1>Your Subscribed Package</h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  <Col xs={12} md={2}></Col>
                  <Col
                    md={8}
                    className={`d-flex flex-column align-items-center mb-4 ${
                      isHoveredGold ? "zoomed-card" : ""
                    }`}
                  >
                    <Image
                      src="./subscrib.svg"
                      style={{
                        paddingBottom: "20px",
                        maxWidth: "100%",
                        height: "auto",
                      }}
                    />
                    <h1 className="month">
                      12
                      <br />
                      Months
                    </h1>
                    <h4 className="rupee">₹</h4>
                    <h1 className="offerPrice">
                      {activePlans.subscription_amount}
                    </h1>
                    <h1 className="planName">{activePlans.title}</h1>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <Row style={{ padding: isMobile ? "0px" : "20px" }}>
                  <Col md={isMobile ? 1 : 2}></Col>
                  <Col
                    md={
                      props.userData.mobile === null
                        ? isMobile
                          ? 3
                          : 4
                        : isMobile
                        ? 2
                        : 3
                    }
                  >
                    <div
                      style={{
                        // backgroundColor: "#58b7aa",
                        // color: "white",
                        // textAlign: "center",
                        // padding: "10px",
                        // margin: "5px", // Added margin for spacing
                        // borderRadius: "9px",
                        // width: "auto",

                        display: "inline-block",
                        padding: "10px",
                        margin: "5px",
                        marginLeft: isMobile ? "5px" : "60px",
                        backgroundColor: "#58b7aa",
                        borderRadius: "9px",
                        textAlign: "center",
                        color: "white",
                        width:
                          props.userData.mobile === null
                            ? isMobile
                              ? "-webkit-fill-available"
                              : "330px"
                            : isMobile
                            ? "-webkit-fill-available"
                            : "220px",
                      }}
                    >
                      <h6>User</h6>
                      <h4
                        style={{
                          textWrap:
                            props.userData.mobile === null ? "true" : "false",
                          wordWrap: "break-word",
                        }}
                      >
                        {props.userData.mobile != null &&
                        props.userData.mobile != ""
                          ? props.userData.mobile
                          : props.userData.email}
                      </h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 3 : 2}>
                    <div
                      style={{
                        backgroundColor: "#58b7aa",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px", // Added margin for spacing
                        borderRadius: "9px",
                      }}
                    >
                      <h6>Expiry Date</h6>
                      <h4>{formatDate(activePlans.expiry_date)}</h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 3 : 2}>
                    <div
                      style={{
                        backgroundColor: "#58b7aa",
                        color: "white",
                        textAlign: "center",
                        padding: "10px",
                        margin: "5px",
                        borderRadius: "9px",
                        width: "auto",
                      }}
                    >
                      <h6>Transaction Id</h6>
                      <h4>XXXX{activePlans.transaction_id}</h4>
                    </div>
                  </Col>
                  <Col md={isMobile ? 1 : 2}></Col>
                </Row>
              </Container>
            </>
          ) : (
            <>
              <Container style={{ padding: "20px", minHeight: "55rem" }}>
                {loading && (
                  <div className="loading-overlay">
                    <Loader isLoading={loading} />
                  </div>
                )}
                <Container>
                  <Row className="justify-content-center">
                    <Col xs={12} className="text-center text-white">
                      <h1>Subscription Packages</h1>
                    </Col>
                  </Row>
                </Container>
                <Row style={{ padding: "20px" }}>
                  {goldPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredGold ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredGold(true)}
                      onMouseLeave={() => setIsHoveredGold(false)}
                      onClick={() => makePayment(goldPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        12
                        <br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{goldPlans.listedPrice}</h1>
                      <h1 className="planName">{goldPlans.title}</h1>
                      <h4 className="oldPrice">₹ {goldPlans.amount}</h4>
                    </Col>
                  )}
                  {silverPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredSilver ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredSilver(true)}
                      onMouseLeave={() => setIsHoveredSilver(false)}
                      onClick={() => makePayment(silverPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        3<br />
                        Months
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{silverPlans.listedPrice}</h1>
                      <h1 className="planName">{silverPlans.title}</h1>
                      <h4 className="oldPrice">₹ {silverPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
                <Row>
                  {basicPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHoveredBasic ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHoveredBasic(true)}
                      onMouseLeave={() => setIsHoveredBasic(false)}
                      onClick={() => makePayment(basicPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        1<br />
                        Month
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{basicPlans.listedPrice}</h1>
                      <h1 className="planName">{basicPlans.title}</h1>
                      <h4 className="oldPrice">₹ {basicPlans.amount}</h4>
                    </Col>
                  )}
                  {sixDaysPlans && (
                    <Col
                      xs={12}
                      md={6}
                      className={`d-flex flex-column align-items-center mb-4 ${
                        isHovered ? "zoomed-card" : ""
                      }`}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                      onClick={() => makePayment(sixDaysPlans)}
                    >
                      <Image
                        src="./subscrib.svg"
                        style={{
                          paddingBottom: "20px",
                          maxWidth: "100%",
                          height: "auto",
                        }}
                      />
                      <h1 className="month">
                        6<br />
                        Days
                      </h1>
                      <h4 className="rupee">₹</h4>
                      <h1 className="offerPrice">{sixDaysPlans.listedPrice}</h1>
                      <h1 className="planName">{sixDaysPlans.title}</h1>
                      <h4 className="oldPrice">₹ {sixDaysPlans.amount}</h4>
                    </Col>
                  )}
                </Row>
              </Container>
            </>
          )}
        </>
      ) : (
        <Container style={{ padding: "20px", minHeight: "55rem" }}>
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} className="text-center text-white">
                <h1>Subscription Packages</h1>
              </Col>
            </Row>
          </Container>
          <Row style={{ padding: "20px" }}>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredGold ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredGold(true)}
              onMouseLeave={() => setIsHoveredGold(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                12
                <br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">99</h1>
              <h1 className="planName">GOLD</h1>
              <h4 className="oldPrice">₹ 300</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredSilver ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredSilver(true)}
              onMouseLeave={() => setIsHoveredSilver(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                3<br />
                Months
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">72</h1>
              <h1 className="planName">SILVER</h1>
              <h4 className="oldPrice">₹ 200</h4>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHoveredBasic ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHoveredBasic(true)}
              onMouseLeave={() => setIsHoveredBasic(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                1<br />
                Month
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">45</h1>
              <h1 className="planName">BASIC</h1>
              <h4 className="oldPrice">₹ 125</h4>
            </Col>

            <Col
              xs={12}
              md={6}
              className={`d-flex flex-column align-items-center mb-4 ${
                isHovered ? "zoomed-card" : ""
              }`}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={() => pushToLogin()}
            >
              <Image
                src="./subscrib.svg"
                style={{
                  paddingBottom: "20px",
                  maxWidth: "100%",
                  height: "auto",
                }}
              />
              <h1 className="month">
                6<br />
                Days
              </h1>
              <h4 className="rupee">₹</h4>
              <h1 className="offerPrice">15</h1>
              <h1 className="planName">SIX DAYS</h1>
              <h4 className="oldPrice">₹ 87</h4>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default Subscribe;

// <div className="container">
//   <div className="row row-grid justify-content-center" style={{ minHeight: '690px' }}>
//     <div className="col-lg-12 packagesBox">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-md-12 pageTtileBox">
//             <h1>Subscription Packages</h1>
//           </div>
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//         <div className="row">
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-0">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 12 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> GOLD </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 300</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 99
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-md-6">
//             <div className="info info-vertical bg-default packageinfoBox innerpack-1">
//               <div className="row innerPackageBox">
//                 <div className="col-sm-4 rightColBox" style={{ backgroundColor: 'transparent' }}>
//                   <h4 className="info-title"> 3 Months </h4>
//                 </div>
//                 <div className="col-sm-8 leftColBox" style={{ backgroundColor: 'transparent' }}>
//                   <div className="text-center">
//                     <button className="btn btn-info packageBtnBox" type="button"> SILVER </button>
//                   </div>
//                   <h1 className="price">
//                     <span className="deletedPrice">
//                       <del><small>₹</small> 200</del>
//                     </span>
//                     <span className="actualPrice">
//                       <small className="currency">₹</small> 72
//                     </span>
//                   </h1>
//                 </div>
//               </div>
//             </div>
//           </div>
//           {/* Add more package columns similarly */}
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
