import React, { useEffect, useState } from "react";
import "./Header.css";
import { Navbar, Button, Modal, Dropdown } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import {
  RiArrowDropDownLine,
  RiHomeGearFill,
  RiLogoutBoxFill,
  RiQuestionAnswerFill,
  RiSettings2Fill,
} from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";
import { useUser } from "../UserContext";
import { googleLogout, useGoogleLogin } from "@react-oauth/google";
import { useActiveTab } from "./common/ActiveTabContext";
function isMobileDevice() {
  return window.matchMedia("(max-width: 1000px)").matches;
}

const Header = (props) => {
  // const [bebuUser, setBebuUser] = useState(null);

  // const FetchUser = () => {
  //   const storedUserData = localStorage.getItem("BebuAppUser");
  //   return storedUserData ? JSON.parse(storedUserData) : null;
  // };

  // const { userData } = useUser();
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [activeKey, setActiveKey] = useState("/");
  const [isUserRegistered, setIsUserRegistered] = useState(false);
  const [showContent, setShowContent] = useState(true);
  const { setTab } = useActiveTab();
  const location = useLocation();
  const pathname = window.location.pathname;
  const urlParts = pathname.split("/");
  const lastElement = urlParts[urlParts.length - 1];
  // const [userData, setUserData] = useState(null);
  const handleSelect = (selectedKey) => {
    setActiveKey(selectedKey);
  };
  // const { userData, logout } = useUser();

  useEffect(() => {
    if (lastElement === "subscription" || lastElement === "quick_subscribe") {
      setShowContent(false);
    } else {
      setShowContent(true);
    }
    if (location.pathname === "/") {
      setActiveKey("/");
    } else if (
      location.pathname === "/browse" ||
      location.pathname === "/media/"
    ) {
      setActiveKey("/browse");
    } else if (location.pathname === "/upcoming") {
      setActiveKey("/upcoming");
    } else if (location.pathname === "/subscribe") {
      setActiveKey("/subscribe");
    }
  }, [location.pathname, activeKey, showContent]);

  useEffect(() => {
    // setBebuUser(FetchUser);

    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    // if (window.location.pathname === "/") {
    //   setTab("/");
    //   //console.log("window.location.pathname", window.location.pathname);
    //   setActiveKey("/");
    // } else if (window.location.pathname === "/browse") {
    //   setTab("/browse");
    //   setActiveKey("/browse");
    // } else if (window.location.pathname === "/upcoming") {
    //   setTab("/upcoming");
    //   setActiveKey("/upcoming");
    // } else if (window.location.pathname === "/subscribe") {
    //   setTab("/subscribe");
    //   setActiveKey("/subscribe");
    // }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.location.pathname, activeKey]);

  const logoutFunc = () => {
    try {
      localStorage.removeItem("NewfcmBebu");
      localStorage.removeItem("BebuAppUser");
      setIsUserRegistered(false);
      //console.log("LOGOUT FUNCTION CALLED");
      googleLogout();
    } catch (error) {
      console.error("Error occurred during logout:", error);
    }
  };

  // const logOutGoogle = () => {
  //   googleLogout();
  //   // setProfileSocial(null);
  // };

  const handleShow = () => {
    // setIsUserRegistered(!isUserRegistered);
  };

  return (
    <>
      {isMobile ? (
        showContent ? (
          <>
            <Navbar
              expand="lg"
              bg="dark"
              variant="pills"
              style={{
                padding: "8px",
                height: "60px",
                display: "flex",
                justifyContent: "space-between", // Horizontal space between brand and dropdown
                alignItems: "center", // Vertical centering
              }}
              // style={{ padding: "8px", height: "60px" }}
            >
              {showContent ? (
                <>
                  <Navbar.Brand
                    as={Link}
                    to="/"
                    style={{ marginBottom: "18px" }}
                  >
                    <img
                      src="./bebu_header.png" //"https://www.bebu.app/assets/img/brand/bebu_final_logo.png"
                      height="40"
                      className="d-inline-block align-top"
                      alt="./bebu_header.png"
                    />
                  </Navbar.Brand>
                </>
              ) : (
                <>
                  <Navbar.Brand style={{ marginBottom: "18px" }}>
                    <img
                      src="./bebu_header.png" //"https://www.bebu.app/assets/img/brand/bebu_final_logo.png"
                      height="40"
                      className="d-inline-block align-top"
                      alt="./bebu_header.png"
                      style={{ marginLeft: "120%" }}
                    />
                  </Navbar.Brand>
                </>
              )}

              {props.userData != null ? ( //marginLeft: "auto", marginRight: "110px"
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Dropdown>
                    <Dropdown.Toggle
                      id="dropdown-button-dark-example1"
                      variant="secondary"
                      style={{
                        // borderRadius: "50%",
                        width: "68px",
                        height: "54px",
                        backgroundColor: "transparent",
                        borderWidth: "0",
                        marginTop: "-25px",
                      }}
                    >
                      <img
                        src={
                          props.userData != undefined
                            ? props.userData.picture
                            : "./user.png"
                        } // Replace 'path_to_your_image' with the actual path to your image
                        // alt="Dropdown Button"
                        style={{
                          borderRadius: "50%",
                          width: "40px",
                          height: "40px",
                          backgroundColor: "black",
                          // marginLeft: "10px",
                        }} // Adjust width and height as needed
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      style={{
                        backgroundColor: "#222529",
                        padding: "10px",
                        right: 0,
                        left: "auto",
                      }}
                    >
                      <Dropdown.Item href="/profile">
                        <RiSettings2Fill style={{ marginRight: "10px" }} />{" "}
                        Profile Settings
                      </Dropdown.Item>
                      <Dropdown.Item href="/support">
                        <RiQuestionAnswerFill style={{ marginRight: "10px" }} />{" "}
                        Support | Help
                      </Dropdown.Item>
                      <Dropdown.Item href="/" onClick={logoutFunc}>
                        <RiLogoutBoxFill style={{ marginRight: "10px" }} /> Sign
                        Out
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/* <img
                  src={props.userData?.profileImageId || "./user.png"}
                  height="40"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "black",
                    marginLeft: "10px",
                  }}
                  alt="./user.png"
                /> */}
                </div>
              ) : (
                <>
                  {showContent && (
                    <Button
                      variant="outline-light"
                      as={Link}
                      to="/login"
                      style={{
                        borderWidth: "0",
                        borderRadius: "20px",
                        marginLeft: "auto",
                        backgroundColor: "#1FB9A9",
                        marginTop: "-20px",
                      }}
                    >
                      Login/Register
                    </Button>
                  )}
                </>
              )}
            </Navbar>
          </>
        ) : (
          <> </>
        )
      ) : showContent ? (
        <>
          <Navbar
            expand="lg"
            bg="dark"
            variant="pills"
            style={{ padding: "8px", height: "55px" }}
          >
            <Navbar.Brand
              as={Link}
              to="/"
              style={{ paddingRight: "60px" }}
              onClick={() => setActiveKey("/")}
            >
              <img
                src="./bebu_header.png" //src="https://www.bebu.app/assets/img/brand/bebu_final_logo.png"
                height="40"
                className="d-inline-block align-top"
                alt="./bebu_header.png"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="mr-auto"
                activeKey={activeKey}
                onSelect={handleSelect}
              >
                <Nav.Item>
                  <Nav.Link eventKey="/">
                    <Button
                      className="nav-button text-white"
                      as={Link}
                      to="/"
                      style={{
                        borderWidth: "0",
                        borderRadius: "20px",
                        backgroundColor:
                          activeKey === "/" ? "#58b7aa" : "#222529",
                        transition: "background-color 0.3s ease",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.10)",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#58b7aa";
                        e.target.style.boxShadow =
                          "0px 6px 12px rgba(1, 1, 1, 1)"; // Lifted box-shadow on hover
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor =
                          activeKey === "/" ? "#58b7aa" : "#222529";
                        e.target.style.boxShadow =
                          "0px 3px 6px rgba(0, 0, 0, 0)"; // Restore initial box-shadow
                      }}
                    >
                      Home
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="/browse">
                    <Button
                      className="nav-button text-white"
                      as={Link}
                      to="/browse"
                      style={{
                        borderWidth: "0",
                        borderRadius: "20px",
                        backgroundColor:
                          activeKey === "/browse" ? "#58b7aa" : "#222529",
                        transition: "background-color 0.3s ease",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.10)",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#58b7aa";
                        e.target.style.boxShadow =
                          "0px 6px 12px rgba(1, 1, 1, 1)"; // Lifted box-shadow on hover
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor =
                          activeKey === "/browse" ? "#58b7aa" : "#222529";
                        e.target.style.boxShadow =
                          "0px 3px 6px rgba(0, 0, 0, 0)"; // Restore initial box-shadow
                      }}
                    >
                      Browse
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="/upcoming">
                    <Button
                      className="nav-button text-white"
                      as={Link}
                      to="/upcoming"
                      style={{
                        borderWidth: "0",
                        borderRadius: "20px",
                        backgroundColor:
                          activeKey === "/upcoming" ? "#58b7aa" : "#222529",
                        transition: "background-color 0.3s ease",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.10)",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#58b7aa";
                        e.target.style.boxShadow =
                          "0px 6px 12px rgba(1, 1, 1, 1)"; // Lifted box-shadow on hover
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor =
                          activeKey === "/upcoming" ? "#58b7aa" : "#222529";
                        e.target.style.boxShadow =
                          "0px 3px 6px rgba(0, 0, 0, 0)"; // Restore initial box-shadow
                      }}
                    >
                      Upcoming
                    </Button>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="/subscribe">
                    <Button
                      className="nav-button text-white"
                      as={Link}
                      to="/subscribe"
                      style={{
                        borderWidth: "0",
                        borderRadius: "20px",
                        backgroundColor:
                          activeKey === "/subscribe" ? "#58b7aa" : "#222529",
                        transition: "background-color 0.3s ease",
                        boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.10)",
                      }}
                      onMouseOver={(e) => {
                        e.target.style.backgroundColor = "#58b7aa";
                        e.target.style.boxShadow =
                          "0px 6px 12px rgba(1, 1, 1, 1)"; // Lifted box-shadow on hover
                      }}
                      onMouseOut={(e) => {
                        e.target.style.backgroundColor =
                          activeKey === "/subscribe" ? "#58b7aa" : "#222529";
                        e.target.style.boxShadow =
                          "0px 3px 6px rgba(0, 0, 0, 0)"; // Restore initial box-shadow
                      }}
                    >
                      Subscribe
                    </Button>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {props.userData != null ? (
                <Dropdown style={{ marginLeft: "auto" }}>
                  <Dropdown.Toggle
                    id="dropdown-button-dark-example1"
                    variant="secondary"
                    style={{
                      borderRadius: "50%",
                      width: "68px",
                      height: "54px",
                      backgroundColor: "transparent",
                      borderWidth: "0",
                      marginBottom: "8px",
                    }}
                  >
                    <img
                      src={
                        props.userData != undefined
                          ? props.userData.picture
                          : "./user.png"
                      } // Replace 'path_to_your_image' with the actual path to your image
                      // alt="Dropdown Button"
                      style={{
                        borderRadius: "50%",
                        width: "40px",
                        height: "40px",
                        backgroundColor: "black",
                        // marginLeft: "10px",
                      }} // Adjust width and height as needed
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    style={{
                      backgroundColor: "#222529",
                      padding: "10px",
                      right: 0,
                      left: "auto",
                    }}
                  >
                    <Dropdown.Item href="/profile">
                      <RiSettings2Fill style={{ marginRight: "10px" }} />{" "}
                      Profile Settings
                    </Dropdown.Item>
                    <Dropdown.Item href="/support">
                      <RiQuestionAnswerFill style={{ marginRight: "10px" }} />{" "}
                      Support | Help
                    </Dropdown.Item>
                    <Dropdown.Item href="/" onClick={logoutFunc}>
                      <RiLogoutBoxFill style={{ marginRight: "10px" }} /> Sign
                      Out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <Button
                  variant="outline-light"
                  as={Link}
                  to="/login"
                  style={{
                    borderWidth: "0",
                    borderRadius: "20px",
                    marginLeft: "auto",
                    backgroundColor: "#58b7aa",
                    transition: "all 0.3s ease", // Apply transition to all properties for smoother animation
                    boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.5)",
                    transform: "translateY(0)", // Initial transform
                  }}
                  onMouseOver={(e) => {
                    e.target.style.color = "white";
                    e.target.style.backgroundColor = "#58b7aa";
                    e.target.style.boxShadow = "0px 6px 12px rgba(1, 1, 1, 1)"; // Lifted box-shadow on hover
                    e.target.style.transform = "translateY(-3px)"; // Lifted by 3 pixels on hover
                  }}
                  onMouseOut={(e) => {
                    e.target.style.color = "white";
                    e.target.style.backgroundColor = "#58b7aa";
                    e.target.style.boxShadow = "0px 3px 6px rgba(0, 0, 0, 0.5)"; // Restore initial box-shadow
                    e.target.style.transform = "translateY(0)"; // Restore initial position
                  }}
                >
                  Login/Register
                </Button>
              )}
            </Navbar.Collapse>
          </Navbar>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Header;
//Mobile View NavBar;;;;
{
  /* <Navbar
            expand="lg"
            bg="dark"
            variant="pills"
            style={{ padding: "8px" }}
          >
            <Navbar.Brand as={Link} to="/" style={{ paddingRight: "60px" }}>
              <img
                src="https://www.bebu.app/assets/img/brand/bebu_final_logo.png"
                height="40"
                className="d-inline-block align-top"
                alt="Logo"
              />
            </Navbar.Brand>
            {isUserRegistered ? (
              <Dropdown style={{}}>
                <Dropdown.Toggle variant="dark"></Dropdown.Toggle>
                <Dropdown.Menu
                  style={{
                    backgroundColor: "#222529",
                    padding: "10px",
                    right: 0,
                    left: "auto",
                  }} // Adjusting menu position
                >
                  <Dropdown.Item href="/profile">
                    {" "}
                    <RiSettings2Fill style={{ marginRight: "10px" }} /> Profile
                    Settings{" "}
                  </Dropdown.Item>
                  <Dropdown.Item href="/support">
                    <RiQuestionAnswerFill style={{ marginRight: "10px" }} />{" "}
                    Support | Help{" "}
                  </Dropdown.Item>
                  <Dropdown.Item href="/">
                    <RiLogoutBoxFill
                      style={{ marginRight: "10px" }}
                      onClick={logout}
                    />{" "}
                    Sign Out{" "}
                  </Dropdown.Item>
                </Dropdown.Menu>
                <img
                  src="./user.png"
                  height="40"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "40px",
                    height: "40px",
                    backgroundColor: "black",
                    marginLeft: "10px", // Adjusting image position
                  }}
                  alt="./user.png"
                />
              </Dropdown>
            ) : (
              <Button
                variant="outline-light"
                as={Link}
                to="/login"
                style={{
                  borderWidth: "0",
                  borderRadius: "20px",
                  marginLeft: "auto",
                  backgroundColor: "#1FB9A9",
                }}
              >
                Login/Register
              </Button>
            )}
          </Navbar> */
}
