import axios from "axios";

const productionBaseUrl = "https://adminpanelconnect.bebu.app/userApi";
const developmentBaseUrl = "https://testadmin.bebu.app/userApi";

const instance = axios.create({
  baseURL: productionBaseUrl,
});

export default instance;

window.getUserIP = async () => {
  try {
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching user IP:", error);
    return "Unknown"; // Return 'Unknown' if there's an error
  }
};
