import instance from "../common/Api";
import "./Cookies.css";
import React, { useEffect, useState } from "react";
import Loader from "../common/Loader";

const RefundPolicy = () => {
  const [htmlContent, setHtmlContent] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    instance.get("/getPage/6").then((response) => {
      let data = response.data.description;

      // Ensure data is a string
      if (typeof data !== "string") {
        data = JSON.stringify(data);
      }

      const tagsToColorGreen = ["h4", "h2"]; // Add more tags if needed

      // Set the HTML content and manipulate it to set text color to green for specified tags
      let modifiedHtmlContent = data;
      tagsToColorGreen.forEach((tag) => {
        modifiedHtmlContent = modifiedHtmlContent.replace(
          new RegExp(`<${tag}(.*?)>`, "g"),
          `<${tag} style="color: #58b7aa;"$1>`
        );
      });
      setLoading(false);
      setHtmlContent(modifiedHtmlContent);
    });
  }, []);

  return (
    <div
      className="container-fluid privacyPolicyImgbox"
      style={{
        width: "100%",
        minHeight: "51rem",
        position: "relative",
        backgroundBlendMode: "overlay",
      }}
    >
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}
      <div className="container privacyPolicyBellowTextBox">
        <div id="scroll">
          <div className="row">
            {htmlContent && (
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefundPolicy;
