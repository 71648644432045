import React, { useEffect, useState } from "react";
import "./Home.css";
// import Carousel from 'react-bootstrap/Carousel';
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import LatestMovies from "./HomeComponents/LatestMovies";
import OriginalMovies from "./HomeComponents/OriginalMovies";
import ShortFilms from "./HomeComponents/ShortFilms";
import WebSeries from "./HomeComponents/WebSeries";
import HomeBanner from "./HomeComponents/HomeBanner";
import instance from "./common/Api";
import { Button, Spinner, Modal, ModalHeader } from "react-bootstrap";
import PWAInstallerPrompt from "react-pwa-installer-prompt";
import CookieConsent from "react-cookie-consent";
import { RiArrowRightSLine, RiDownload2Fill } from "react-icons/ri";
import { generateToken } from "../firebase";
import Loader from "./common/Loader";
import { Helmet } from "react-helmet";
import usePWADetection from "../Components/usePWADetection";

// import "react-cookie-consent/dist/cookie-consent.css";

const Home = (props) => {
  function isMobileDevice() {
    return window.matchMedia("(max-width: 1000px)").matches;
  }
  const [data, setData] = useState(null);
  const [dataMobileSection_1, setDataMobileSection_1] = useState(null);

  const [mobileOriginals, setMobileOriginals] = useState(null);
  const [mobileShortFilm, setMobileShortFilm] = useState(null);
  const [mobileWebSeries, setMobileWebSries] = useState(null);

  const [dataMobileSection_2, setDataMobileSection_2] = useState(null);
  const [bannerData, setBannerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(isMobileDevice());
  const [showModelForInstall, setshowModelForInstall] = useState(false);
  const [show, setShow] = useState(false);
  const [FCM_Sent, setFCM_Sent] = useState(true);
  const [newToken, setNewToken] = useState("");

  const handleClose = () => {
    setshowModelForInstall(false);
  };
  // const handleShow = () => setShow(true);

  // const sendTokenToServer = async () => {
  //   const token = await generateToken();
  //   if (token) {
  //     console.log(token);
  //     await updateNotification(token);
  //   }
  // };

  useEffect(() => {
    // sendTokenToServer();

    // // console.log(window.navigator.userAgent); // User agent string
    // // console.log(window.navigator.platform); // Operating system platform
    // // console.log(window.navigator.language); // Language
    // // const tokenSent = localStorage.getItem("tokenSent");
    // const storedUserFcmToken = localStorage.getItem("NewfcmBebu").replace(/"/g, "");
    // const oldStoredUserFcmToken =
    //   localStorage.getItem("oldfcm") &&
    //   localStorage.getItem("oldfcm").replace(/"/g, "");
    // // const stringWithoutQuotes = storedUserFcmToken.replace(/"/g, "");
    // if (storedUserFcmToken !== null) {
    //   // console.log(storedUserFcmToken, "<stringWithoutQuotes");
    //   // console.log(oldStoredUserFcmToken, "stringWithoutQuotes");
    //   if (storedUserFcmToken !== oldStoredUserFcmToken) {
    //     // console.log(storedUserFcmToken, "stringWithoutQuotes");
    //     // console.log(oldStoredUserFcmToken, "stringWithoutQuotes");
    //     localStorage.setItem("oldfcm", JSON.stringify(storedUserFcmToken));

    //     instance
    //       .post(
    //         `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${storedUserFcmToken}&FINGERPRINT=${window.navigator.userAgent}&DEVICE_LANGUAGE=${window.navigator.language}&ip=${props.ip}`
    //       )
    //       .then((response) => {
    //         console.warn(response.data);
    //       })
    //       .catch((error) => {
    //         console.error("Error fetching data:", error);
    //       })
    //       .finally(() => {});
    //   }
    // }

    setFCM_Sent(false);
    // localStorage.setItem("tokenSent", JSON.stringify(FCM_Sent));

    // if (FCM_Sent) {
    //   setFCM_Sent(false);
    //   instance
    //     .post(
    //       `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${storedUserFcmToken}&FINGERPRINT=${window.navigator.userAgent}&DEVICE_LANGUAGE={window.navigator.language}`
    //     )
    //     .then((response) => {
    //       console.warn(response.data);
    //     })
    //     .catch((error) => {
    //       console.error("Error fetching data:", error);
    //     })
    //     .finally(() => {});
    // }
    // }
  }, []); // Run this effect only once on initial mount

  const updateNotification = async (token) => {
    // console.log(token, "updateNotification");
    try {
      await instance.post(
        `/saveUnauthUsersDeviceDetails?id=1&FCM_TOKEN=${token}&FINGERPRINT=${window.navigator.userAgent}&DEVICE_LANGUAGE=${window.navigator.language}`
      );
      console.log("FCM token saved on the server successfully.");
    } catch (error) {
      console.error("Error saving FCM token:", error);
    }
  };
  useEffect(() => {
    // const notificationInteracted = localStorage.getItem("pwaInteracted0");

    // If the notification hasn't been interacted with and 10 seconds have passed, show it
    const timeoutId = setTimeout(() => {
      setshowModelForInstall(true);
    }, 3000);

    // Clear the timeout when the component unmounts
    return () => clearTimeout(timeoutId);
  }, []); // Run this effect only once on initial mount

  useEffect(() => {
    const fetchWebDataWithDelay = setTimeout(() => {
      if (!isMobile) {
        instance
          .get(`/contentBycatAndPopular?ip=${props.ip}`)
          .then((response) => {
            setData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        instance
          .post(`/home_first_section?id=1&ip=${props.ip}`)
          .then((response) => {
            // console.log(response.data.data[1].data);
            setDataMobileSection_1(response.data.data[1].data);
            // setData(response.data);
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          })
          .finally(() => {
            MobileSecondSection();
            // setLoading(false);
          });
      }
    }, 1000);
    //
    function handleResize() {
      setIsMobile(isMobileDevice());
    }

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      clearTimeout(fetchWebDataWithDelay);
    };
    // return () => clearTimeout(fetchWebDataWithDelay);
  }, [isMobile]);

  const MobileSecondSection = () => {
    instance
      .post(`/home_second_section?id=1&ip=${props.ip}`)
      .then((response) => {
        // console.log(response.data.data, "setDataMobileSection_2");
        if (response.data.data[0].title === "Originals") {
          // console.log(response.data.data[0].data, "setMobileOriginals");
          setMobileOriginals(response.data.data[0].data);
        }
        if (response.data.data[1].title === "Short Film") {
          setMobileShortFilm(response.data.data[1].data);
        }
        if (response.data.data[2].title === "Web Series") {
          setMobileWebSries(response.data.data[2].data);
        }
        setDataMobileSection_2(response.data.data[1].data);
        // setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchDataWithDelay = setTimeout(() => {
      instance
        .get(`/home_sliders_web?ip=${props.ip}`)
        .then((response) => {
          setBannerData(response.data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Set loading to false when fetch is complete
        });
    }, 1000);
    return () => clearTimeout(fetchDataWithDelay);
  }, []);

  // const handleNotificationInteraction = () => {
  //   // Set a flag in local storage indicating that the notification has been interacted with
  //   localStorage.setItem("pwaInteracted0", "true");
  //   setshowModelForInstall(false);
  // };
  return (
    <>
      {loading && (
        <div className="loading-overlay">
          <Loader isLoading={loading} />
        </div>
      )}

      <div
        style={
          isMobile
            ? {
                width: "100%",
                minHeight: "55rem",
                // position: "relative",
                backgroundImage: `url(./bebuAppBG.JPEG)`,
                backgroundBlendMode: "overlay",
                backgroundSize: "contain",
                // backgroundPosition: "center",
                backgroundColor: "#141414",
                backgroundRepeat: "repeat-y",
              }
            : null
        }
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>Bebu.app</title>
          <link rel="canonical" href="http://mysite.com/example" />
        </Helmet>
        <PWAInstallerPrompt
          render={({ onClick }) => (
            <Modal show={showModelForInstall} onHide={handleClose}>
              <ModalHeader
                style={{
                  padding: "0",
                  backgroundColor: "black",
                  borderBottom: "none",
                }}
              >
                <Button
                  variant="secondary"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    width: "30px",
                    height: "30px",
                    padding: "0px",
                    marginLeft: "auto",
                    marginRight: "10px",
                  }}
                  onClick={handleClose}
                >
                  x
                </Button>
              </ModalHeader>
              <Modal.Body
                style={{
                  backgroundImage: `url(./check-network.png)`,
                  backgroundSize: "cover",
                  color: "white",
                  textAlign: "center",
                  paddingTop: "20px",
                  paddingBottom: "20px",

                  // height: "15rem", // Adjust as needed
                  backgroundColor: "black",
                }}
              >
                <RiDownload2Fill
                  className="moving"
                  style={{ height: "50px", fontSize: "28px", color: "#e20788" }}
                />
                <h6>Install Bebu on your device!</h6>

                <Button
                  variant="secondary"
                  style={{
                    color: "white",
                    backgroundColor: "#58b7aa",
                  }}
                  onClick={onClick}
                >
                  Install
                </Button>
              </Modal.Body>
            </Modal>
          )}
          // callback={(data) => console.log(data)}
          callback={(data) => {
            if (data.isInstallSuccess) {
              localStorage.setItem("login_with", "Install");
            }
          }}
        />

        {bannerData && (
          <HomeBanner
            banner={bannerData}
            style={{ backgroundColor: "#141414" }}
            isMobile={isMobile}
          />
        )}
        {isMobile
          ? dataMobileSection_1 && (
              <LatestMovies data={dataMobileSection_1} isMobile={isMobile} />
            )
          : data &&
            data["0"] && (
              <LatestMovies
                data={data["0"].mediaContentByCategory.find(
                  (category) => category.id === "Latest"
                )}
                isMobile={isMobile}
              />
            )}

        {isMobile ? (
          <div style={{ backgroundColor: "transparent" }}>
            {mobileOriginals && (
              <OriginalMovies data={mobileOriginals} isMobile={isMobile} />
            )}
          </div>
        ) : (
          <div style={{ backgroundColor: "transparent" }}>
            {data && data["0"] && (
              <OriginalMovies
                data={data["0"].mediaContentByCategory.find(
                  (category) => category.id === "Originals"
                )}
                isMobile={isMobile}
              />
            )}
          </div>
        )}

        {isMobile
          ? mobileShortFilm && (
              <ShortFilms data={mobileShortFilm} isMobile={isMobile} />
            )
          : data &&
            data["0"] && (
              <ShortFilms
                data={data["0"].mediaContentByCategory.find(
                  (category) => category.id === "Short Film"
                )}
                isMobile={isMobile}
              />
            )}

        {isMobile ? (
          <div
            style={{
              // backgroundColor: "black",
              marginBottom: "0",
              paddingBottom: "3rem",
            }}
          >
            {mobileWebSeries && (
              <WebSeries data={mobileWebSeries} isMobile={isMobile} />
            )}
          </div>
        ) : (
          <div
            style={{
              // backgroundColor: "black",
              marginBottom: "0",
              paddingBottom: "1rem",
            }}
          >
            {data && data["0"] && (
              <WebSeries
                data={data["0"].mediaContentByCategory.find(
                  (category) => category.id === "Web Series"
                )}
                isMobile={isMobile}
              />
            )}
          </div>
        )}
      </div>
      {isMobile ? (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(88 183 170 / 90%)",
            zIndex: "1999",
            marginBottom: "50px",
            flexWrap: "none",
          }}
          buttonStyle={{
            background: "#e20788",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
          }}
          expires={150}
        >
          This website uses cookies.
          <a
            href="/CookiePolicy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      ) : (
        <CookieConsent
          location="bottom"
          buttonText="OK!"
          cookieName="myCookieConsent"
          style={{
            background: "rgb(88 183 170 / 90%)",
            zIndex: "1999",
            bottom: "0px",
          }}
          buttonStyle={{
            background: "#e20788",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
            width: "120px",
          }}
          expires={150}
        >
          This website uses cookies to ensure you get the best experience on our
          website.
          <a
            href="/CookiePolicy"
            style={{ color: "black", textDecoration: "underline" }}
          >
            Learn More
          </a>
        </CookieConsent>
      )}
    </>
  );
};

export default Home;
