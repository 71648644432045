import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import "./videoJsPlayer.css";
import { Button } from "react-bootstrap";
import instance from "../common/Api";
// import { FullScreen, useFullScreenHandle } from "react-full-screen";

const VideoJsPlayer = (props) => {
  const demolink =
    "https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8";
  const videoRef = useRef(null);

  const overlayRef = useRef(null);

  const overlayRef_full = useRef(null);

  const location = useLocation();
  // const handle = useFullScreenHandle();
  // const { src } = location.state;
  const navigate = useNavigate();
  const { src, poster, isMobile, handleVideoChange, userData } = props;
  // console.log("in the videojs player props are,,,", props);
  const [player, setPlayer] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isVisibleMobile, setIsVisibleMobile] = useState(false);
  const [isVisibleButtons, setIsVisibleButtons] = useState(false);

  const [autoplay, setAutoplay] = useState(true);
  const [showFull, setShowFull] = useState(
    window.matchMedia("(max-width: 700px)").matches
  );
  const [currentUser, setCurrentUser] = useState(null);
  const [orientation, setOrientation] = useState(window.orientation);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  // const [showOverlay, setShowOverlay] = useState(false);
  useEffect(() => {
    const vjplayer = videojs(videoRef.current, {
      poster: poster,
      autoplay: true,
      controls: true,
      // preload: "auto",
      responsive: true,
      fluid: true,
      displayCurrentQuality: true,
      displayQualitySelector: true,
      displayBitrateOption: true,
      controlText: {
        //vjs-menu-item-text
      },
      sources: [
        {
          src: src,
          type: "application/x-mpegURL",
          withCredentials: true,
        },
      ],
      html5: {
        nativeAudioTracks: true,
        nativeVideoTracks: true,
        nativeTextTracks: true,
      },
    });

    // instance
    //   .get("/getAllCookies")
    //   .then((response) => {
    //     const newCoockies = response.data;
    //     vjplayer.tech().vhs.xhr.beforeRequest = function (options) {
    //       newCoockies.forEach((cookie) => {
    //         options.headers[cookie.key] = cookie.value;
    //       });
    //       return options;
    //     };
    //   })

    //   .catch((error) => {
    //     console.error("Error fetching cookies:", error);
    //   });

    setPlayer(vjplayer);
    changeVideo(src, poster);
  }, [src, poster]);

  useEffect(() => {
    const handleOrientationChange = () => {
      setOrientation(window.orientation);
    };

    window.addEventListener("orientationchange", handleOrientationChange);

    if (orientation === 90) {
      if (player) {
        player.requestFullscreen();
        // player.requestFullscreen();
        player.currentTime(player.currentTime());
        // setIsFullscreen(true);
      } else {
        player.currentTime(player.currentTime());
      }
    } else {
      if (player) {
        player.currentTime(player.currentTime());
        // setIsFullscreen(false);
      }
    }

    if (!isMobile) {
      if (player != null) {
        if (player && !player.paused()) {
          setIsPlaying(true);
        } else {
          setIsPlaying(false);
        }
      }
    }

    // console.warn(isFullscreen);
    return () => {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, [orientation, isFullscreen]);

  useEffect(() => {
    if (userData != null) {
      setCurrentUser(userData);
      // console.warn(userData, "FROM PLAYER");
    }
    //console.log("....SRC: ", src);
    let timeout;
    // if (!isVisible && !player.paused()) {
    //   timeout = setTimeout(() => {
    //     setIsVisible(true);
    //   }, 3000); // 5 seconds in milliseconds
    // }
    if (isVisibleButtons && player.play()) {
      timeout = setTimeout(() => {
        setIsVisibleButtons(false);
      }, 3000); // 5 seconds in milliseconds
    }

    // if (player != null) {

    // }

    // if (isVisibleButtons && player.play()) {
    //   timeout = setTimeout(() => {
    //     setIsVisibleButtons(false);
    //   }, 3000); // 5 seconds in milliseconds
    // }

    return () => {
      clearTimeout(timeout);
    };
  }, [isVisibleButtons, isVisible, player, src]);

  // Function to handle going back 10 seconds
  const handleBack10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() - 10);
    }
  };

  // Function to handle video change
  const changeVideo = (newVideoUrl, poster) => {
    handleVideoChange(newVideoUrl, poster);
    if (player) {
      //console.log("....PLAYER,:", newVideoUrl, poster);
      player.src(newVideoUrl);
      player.poster(poster);
      // player.currentTime(player.currentTime() - player.currentTime());
    }
  };

  // Function to handle playing or pausing the video
  const handlePlayPause = () => {
    if (player) {
      if (player.paused()) {
        setIsPlaying(true);
        player.play();
      } else {
        setIsPlaying(false);
        player.pause();
      }
    }
  };

  useEffect(() => {
    if (player) {
      player.hlsQualitySelector({ displayCurrentQuality: true });
    }
  }, [player]);

  // Function to handle forwarding 10 seconds
  const handleForward10Seconds = () => {
    if (player) {
      player.currentTime(player.currentTime() + 10);
    }
  };

  // useEffect(() => {
  //   const handleOrientationChange = () => {
  //     setOrientation(window.orientation);
  //   };

  //   const handleFullscreenChange = () => {
  //     const isFull = !!document.fullscreenElement;
  //     setIsFullscreen(isFull);
  //   };

  //   window.addEventListener("orientationchange", handleOrientationChange);
  //   document.addEventListener("fullscreenchange", handleFullscreenChange);

  //   return () => {
  //     window.removeEventListener("orientationchange", handleOrientationChange);
  //     document.removeEventListener("fullscreenchange", handleFullscreenChange);
  //   };
  // }, []);

  const handleFullscreenToggle = () => {
    const player = videoRef.current;
    if (!isFullscreen) {
      player.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  const handleVideoClick = () => {
    console.log("Overlay button clicked");
    // Handle button click event
  };

  return (
    <div>
      <video
        ref={videoRef}
        className="video-js vjs-default-skin"
        playsInline
        style={{
          width: "100%",
          height: isMobile ? "25%" : "100%",
        }}
        src={src}
      />
      {/* <FullScreen handle={handle}>Any fullscreen content here</FullScreen> */}
      <button onClick={handleFullscreenToggle}>
        {isFullscreen ? "Exit Fullscreen" : "Enter Fullscreen"}
      </button>
      {isFullscreen && (
        <div className="fullscreen-overlay">
          <button className="overlay-button" onClick={handleVideoClick}>
            Click Me
          </button>
        </div>
      )}
      {/* {isFullscreen && (
        <div className="fullscreen-overlay">
          <button className="overlay-button" onClick={handleVideoClick}>
            Click Me
          </button>
        </div>
      )} */}

      {/* {showOverlay && <div className="overlay-new">Overlay Content</div>} */}
      {!isVisibleMobile &&
        (isMobile ? (
          <div
            ref={overlayRef}
            style={{
              display: "flex",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "22%",
              backgroundColor: "#00000000", //"#00000000",
            }}
            onClick={() => setIsVisibleButtons(true)}
          >
            {isVisibleButtons ? (
              <div
                ref={overlayRef}
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#00000000", //"#00000000",
                }}
              >
                <>
                  <button className="skip-button" onClick={handleBack10Seconds}>
                    <img
                      src="./tenBack.jpg"
                      className="d-inline-block align-top rounded-circle"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#00000000",
                      }}
                      alt="./tenBack.jpg"
                    />
                  </button>
                  <button className="skip-button" onClick={handlePlayPause}>
                    {player && !player.paused() ? (
                      <img
                        src="./pause.jpg"
                        className="d-inline-block align-top rounded-circle"
                        style={{
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#00000000",
                        }}
                        alt="./pause.jpg"
                      />
                    ) : (
                      <img
                        src="./play-button.jpg"
                        className="d-inline-block align-top rounded-circle"
                        style={{
                          borderRadius: "50%",
                          width: "50px",
                          height: "50px",
                          backgroundColor: "#00000000",
                        }}
                        alt="./play-button.jpg"
                      />
                    )}
                  </button>

                  <button
                    className="skip-button"
                    onClick={handleForward10Seconds}
                  >
                    <img
                      src="./tenFarw.jpg"
                      className="d-inline-block align-top rounded-circle"
                      style={{
                        borderRadius: "50%",
                        width: "50px",
                        height: "50px",
                        backgroundColor: "#00000000",
                      }}
                      alt="./tenFarw.jpg"
                    />
                  </button>
                </>
              </div>
            ) : (
              // <>
              //   <Button className="skip-button" onClick={handleBack10Seconds}>
              //     <img
              //       src="./tenBack.jpg"
              //       className="d-inline-block align-top rounded-circle"
              //       style={{
              //         borderRadius: "50%",
              //         width: "50px",
              //         height: "50px",
              //         backgroundColor: "#00000000",
              //       }}
              //       alt="./tenBack.jpg"
              //     />
              //   </Button>
              //   <Button className="skip-button" onClick={handlePlayPause}>
              //     {player && !player.paused() ? (
              //       <img
              //         src="./pause.jpg"
              //         className="d-inline-block align-top rounded-circle"
              //         style={{
              //           borderRadius: "50%",
              //           width: "50px",
              //           height: "50px",
              //           backgroundColor: "#00000000",
              //         }}
              //         alt="./pause.jpg"
              //       />
              //     ) : (
              //       <img
              //         src="./play-button.jpg"
              //         className="d-inline-block align-top rounded-circle"
              //         style={{
              //           borderRadius: "50%",
              //           width: "50px",
              //           height: "50px",
              //           backgroundColor: "#00000000",
              //         }}
              //         alt="./play-button.jpg"
              //       />
              //     )}
              //   </Button>

              //   <Button
              //     className="skip-button"
              //     onClick={handleForward10Seconds}
              //   >
              //     <img
              //       src="./tenFarw.jpg"
              //       className="d-inline-block align-top rounded-circle"
              //       style={{
              //         borderRadius: "50%",
              //         width: "50px",
              //         height: "50px",
              //         backgroundColor: "#00000000",
              //       }}
              //       alt="./tenFarw.jpg"
              //     />
              //   </Button>
              // </>
              <></>
            )}
          </div>
        ) : (
          <></>
        ))}

      {isVisible &&
        (isMobile ? (
          <></>
        ) : (
          // <div
          //   ref={overlayRef}
          //   style={{
          //     display: "flex",
          //     position: "absolute",
          //     top: "0",
          //     left: "0",
          //     width: "100%",
          //     height: "22%",
          //     backgroundColor: "red",
          //   }}
          // >
          //   <Button className="skip-button" onClick={handleBack10Seconds}>
          //     <img
          //       src="./tenBack.jpg"
          //       className="d-inline-block align-top rounded-circle"
          //       style={{
          //         borderRadius: "50%",
          //         width: "50px",
          //         height: "50px",
          //         backgroundColor: "#00000000",
          //       }}
          //       alt="./tenBack.jpg"
          //     />
          //   </Button>
          //   <Button className="skip-button" onClick={handlePlayPause}>
          //     {player && !player.paused() ? (
          //       <img
          //         src="./pause.jpg"
          //         className="d-inline-block align-top rounded-circle"
          //         style={{
          //           borderRadius: "50%",
          //           width: "50px",
          //           height: "50px",
          //           backgroundColor: "#00000000",
          //         }}
          //         alt="./pause.jpg"
          //       />
          //     ) : (
          //       <img
          //         src="./play-button.jpg"
          //         className="d-inline-block align-top rounded-circle"
          //         style={{
          //           borderRadius: "50%",
          //           width: "50px",
          //           height: "50px",
          //           backgroundColor: "#00000000",
          //         }}
          //         alt="./play-button.jpg"
          //       />
          //     )}
          //   </Button>

          //   <Button className="skip-button" onClick={handleForward10Seconds}>
          //     <img
          //       src="./tenFarw.jpg"
          //       className="d-inline-block align-top rounded-circle"
          //       style={{
          //         borderRadius: "50%",
          //         width: "50px",
          //         height: "50px",
          //         backgroundColor: "#00000000",
          //       }}
          //       alt="./tenFarw.jpg"
          //     />
          //   </Button>
          // </div>
          <div
            ref={overlayRef}
            style={{
              display: "flex",
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "90%",
              backgroundColor: "#00000000",
            }}
          >
            <button className="skip-button-web-1" onClick={handleBack10Seconds}>
              <img
                src="./tenBack.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenBack.jpg"
              />
            </button>
            <button className="skip-button-web" onClick={handlePlayPause}>
              {isPlaying ? (
                <img
                  src="./pause.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./pause.jpg"
                />
              ) : (
                <img
                  src="./play-button.jpg"
                  className="d-inline-block align-top rounded-circle"
                  style={{
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#00000000",
                  }}
                  alt="./play-button.jpg"
                />
              )}
            </button>

            <button
              className="skip-button-web"
              onClick={handleForward10Seconds}
            >
              <img
                src="./tenFarw.jpg"
                className="d-inline-block align-top rounded-circle"
                style={{
                  borderRadius: "50%",
                  width: "50px",
                  height: "50px",
                  backgroundColor: "#00000000",
                }}
                alt="./tenFarw.jpg"
              />
            </button>
          </div>
        ))}
    </div>
    // <div data-vjs-player>
    //   <video
    //     ref={videoRef}
    //     className="video-js vjs-default-skin"
    //     playsInline
    //     style={{ width: "100%", height: isMobile ? "25%" : "100%" }}
    //     src={src} // Provide the video source URL
    //   />
    // </div>
  );
};

export default VideoJsPlayer;
